exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-info-jsx": () => import("./../../../src/pages/info.jsx" /* webpackChunkName: "component---src-pages-info-jsx" */),
  "component---src-pages-literatur-index-jsx": () => import("./../../../src/pages/literatur/index.jsx" /* webpackChunkName: "component---src-pages-literatur-index-jsx" */),
  "component---src-pages-literatur-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/literatur/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-literatur-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-post-list-js": () => import("./../../../src/templates/blog-post-list.js" /* webpackChunkName: "component---src-templates-blog-post-list-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

